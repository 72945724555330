<template>
  <as-form id="user-form" :title="title" @onSubmit="submit(!v$.$invalid)">
    <template #body>
      <div class="col">
        <div class="p-fluid grid">
          <div class="lg:col-3 col-6 lg:col-offset-0 col-offset-3">
            <img class="profile-img" :src="image ? image : noImg" />
            <FileUpload
              v-if="!isClosed"
              class="button-default file-upload"
              mode="basic"
              :customUpload="true"
              @select="onSelectedProfileImage"
              accept="image/*"
              :chooseLabel="$t('user_form.choose_img_button')"
              :multiple="false"
            />
          </div>
          <div class="lg:col-9 col-12">
            <div class="p-fluid grid">
              <div class="field col-12">
                <div class="p-float-label">
                  <InputNumber
                    type="text"
                    v-model="v$.idLdap.$model"
                    :class="{ 'p-invalid': v$.idLdap.$invalid && submitted }"
                    :disabled="user"
                  />
                  <label>{{ $t("user_form.ldap_field") }}</label>
                </div>
                <as-input-error
                  :errors="v$.idLdap.$silentErrors"
                  v-show="submitted"
                />
              </div>
              <div class="field col-12">
                <div class="p-float-label">
                  <InputText
                    type="text"
                    v-model="v$.prenom.$model"
                    @update:modelValue="capitalize"
                    :class="{ 'p-invalid': v$.prenom.$invalid && submitted }"
                  />
                  <label>{{ $t("user_form.firstname_field") }}</label>
                </div>
                <as-input-error
                  :errors="v$.prenom.$silentErrors"
                  v-show="submitted"
                />
              </div>
              <div class="field col-12">
                <div class="p-float-label">
                  <InputText
                    type="text"
                    v-model="v$.nom.$model"
                    :class="{ 'p-invalid': v$.nom.$invalid && submitted }"
                    @update:modelValue="upperCase"
                  />
                  <label>{{ $t("user_form.lastname_field") }}</label>
                </div>
                <as-input-error
                  :errors="v$.nom.$silentErrors"
                  v-show="submitted"
                />
              </div>
              <div class="field col-12">
                <div class="p-float-label">
                  <InputText
                    type="text"
                    v-model="v$.mobile.$model"
                    :class="{ 'p-invalid': v$.mobile.$invalid && submitted }"
                  />
                  <label>{{ $t("user_form.mobile_field") }}</label>
                </div>
                <as-input-error
                  :errors="v$.mobile.$silentErrors"
                  v-show="submitted"
                />
              </div>
              <div class="field col-12">
                <div class="p-float-label">
                  <InputText
                    type="text"
                    v-model="v$.email.$model"
                    :class="{ 'p-invalid': v$.email.$invalid && submitted }"
                  />
                  <label>{{ $t("user_form.email_field") }}</label>
                </div>
                <as-input-error
                  :errors="v$.email.$silentErrors"
                  v-show="submitted"
                />
              </div>
              <div class="field col-12">
                <div class="p-float-label">
                  <InputText
                    type="text"
                    v-model="v$.workchatLink.$model"
                    :class="{ 'p-invalid': v$.email.$invalid && submitted }"
                  />
                  <label>{{ $t("user_form.workchat_field") }}</label>
                </div>
                <as-input-error
                  :errors="v$.workchatLink.$silentErrors"
                  v-show="submitted"
                />
              </div>
              <div class="field col-12">
                <div class="p-float-label">
                  <Dropdown
                    v-model="v$.entreprise.$model"
                    :options="entreprises"
                    optionLabel="label"
                    :class="{
                      'p-invalid': v$.entreprise.$invalid && submitted,
                    }"
                  />
                  <label>{{ $t("user_form.company_field") }}</label>
                </div>
                <as-input-error
                  :errors="v$.entreprise.$silentErrors"
                  v-show="submitted"
                />
              </div>
              <div class="field col-12">
                <div class="p-float-label">
                  <Dropdown
                    v-model="v$.type.$model"
                    :options="types"
                    optionLabel="label"
                    :class="{
                      'p-invalid': v$.type.$invalid && submitted,
                    }"
                  />
                  <label>{{ $t("user_form.role_field") }}</label>
                </div>
                <as-input-error
                  :errors="v$.type.$silentErrors"
                  v-show="submitted"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </as-form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, numeric, url } from "@vuelidate/validators";

import AsForm from "@/components/Forms/AsForm.vue";
import AsInputError from "@/components/Forms/AsInputError.vue";

import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import FileUpload from "primevue/fileupload";
import Dropdown from "primevue/dropdown";

import roles from "../../constantes/roles";
import { bufferToJpegBase64 } from "../../util";
export default {
  name: "UserForm",
  components: {
    AsForm,
    AsInputError,
    InputText,
    InputNumber,
    FileUpload,
    Dropdown,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    entreprises: Array,
    user: Object,
    isClosed: Boolean,
  },
  data() {
    return {
      title: "",
      submitted: false,
      idLdap: 0,
      entreprise: undefined,
      type: undefined,
      prenom: "",
      nom: "",
      mobile: "",
      email: "",
      workchatLink: "",
      image: undefined,
      file: undefined,
      noImg: require("../../assets/images/no-user-image.jpg"),
    };
  },
  computed: {
    types() {
      let result = [];
      for (const property in roles) {
        result.push({
          label: this.$t(`roles.${property}`),
          value: roles[property],
        });
      }
      return result;
    },
  },
  watch: {
    isClosed(newValue) {
      if (newValue) {
        this.resetForm();
      }
    },
    user(newValue) {
      if (newValue === undefined) {
        this.resetForm();
      } else {
        try {
          this.title = this.$t("user_form.update_title");

          this.idLdap = newValue.idLdap;
          this.entreprise = this.entreprises.find(
            (el) => el.value === newValue.idEntreprise
          );
          this.type = this.types.find((el) => el.value === newValue.type);
          this.nom = newValue.nom;
          this.prenom = newValue.prenom;
          this.mobile = newValue.mobile;
          this.email = newValue.email;
          this.workchatLink = newValue.workchatLink;

          if (newValue.photo && newValue.photo.data.length > 0) {
            this.image = bufferToJpegBase64(newValue.photo.data);
          } else {
            this.image = undefined;
          }
          this.file = undefined;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  validations() {
    return {
      idLdap: { required, numeric },
      type: { required },
      entreprise: { required },
      prenom: { required },
      nom: { required },
      mobile: { required },
      email: { required, email },
      workchatLink: { url },
    };
  },
  emits: ["onSubmit"],
  methods: {
    upperCase(value) {
      this.nom = value.toUpperCase();
    },
    capitalize(value) {
      this.prenom = value.replace(/\b\w{1}/g, (letter) => letter.toUpperCase());
    },
    submit(isFormValid) {
      this.submitted = true;
      if (!isFormValid) {
        return;
      } else {
        this.$emit("onSubmit", {
          data: {
            idLdap: this.idLdap,
            idEntreprise: this.entreprise.value,
            type: this.type.value,
            nom: this.nom,
            prenom: this.prenom,
            mobile: this.mobile,
            email: this.email,
            workchatLink: this.workchatLink,
            file: this.file,
          },
          updated: this.user ? true : false,
        });
      }
    },
    resetForm() {
      this.title = this.$t("user_form.add_title");
      this.idLdap = 0;
      this.entreprise = undefined;
      this.type = undefined;
      this.prenom = "";
      this.nom = "";
      this.mobile = "";
      this.email = "";
      this.image = undefined;
      this.file = undefined;
    },
    onSelectedProfileImage(event) {
      this.file = event.files[0];
      this.image = event.files[0].objectURL;
    },
  },
};
</script>

<style lang="scss">
#user-form {
  .profile-img {
    width: 100%;
    border-radius: 5px;
  }
  .file-upload {
    width: 100%;
    .p-button {
      width: 100%;
    }
  }
}
</style>

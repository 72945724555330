<template>
  <div id="pdfvuer">
    <pdf
      :src="pdfdata"
      v-for="i in numPages"
      :ref="'page-' + i"
      :key="i"
      :id="i"
      :page="i"
      style="width: 100%; margin: 20px auto"
      :annotation="true"
      :resize="true"
    >
      <template slot-props="loading"> ... </template>
    </pdf>
  </div>
</template>

<script>
import pdfvuer from "pdfvuer";
import "pdfjs-dist/build/pdf.worker.entry";

export default {
  name: "PdfViewer",
  components: {
    pdf: pdfvuer,
  },
  props: {
    pdfUrl: String,
  },
  data() {
    return {
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
    };
  },
  mounted() {
    this.getPdf();
  },
  methods: {
    getPdf() {
      this.pdfdata = pdfvuer.createLoadingTask(this.pdfUrl);
      this.pdfdata.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
  },
};
</script>
<style lang="css" scoped>
#pdfvuer {
  height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Page content */
.content {
  padding: 16px;
}
</style>
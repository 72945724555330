<template>
  <div id="community">
    <as-container>
      <div class="grid">
        <div class="col-12">
          <as-title gutters="20px" :title="$t('community.title')" :size="1" />
        </div>
      </div>
      <div class="grid flex align-items">
        <div class="filters lg:col-6 lg:col-offset-3 col-12">
          <Dropdown
            v-model="selectedEntreprise"
            :options="entreprisesOptions"
            showClear
            optionLabel="label"
            :placeholder="$t('community.select_company')"
            @change="onSelectCompany"
          />
        </div>
        <div class="lg:col-2 col-12 admin flex justify-content-center" v-if="isAdmin">
          <Button
            v-show="!editMode && isAdmin"
            @click="editMode = true"
            icon="pi pi-pencil"
            :label="$t('community.enter_edit_mode')"
            class="p-button p-button-rounded button-default"
          />
          <Button
            v-show="editMode && isAdmin"
            @click="editMode = false"
            icon="pi pi-sign-out"
            :label="$t('community.quit_edit_mode')"
            class="p-button p-button-rounded button-default"
          />
        </div>
      </div>

      <div class="flex flex-wrap justify-content-center safety-card-container">
        <add-safety-block v-if="isAdmin && editMode" @onClick="showUserModal" />
        <ProgressSpinner v-if="isBusy" />
        <safety-card-block
          v-else
          v-for="(user, index) in filteredUsers"
          :key="index"
          :user="user"
          :entreprise="getEntreprise(user.idEntreprise)"
          :editable="isAdmin && editMode"
          @onDelete="deleteUser"
          @onUpdate="showUpdateUserModal(user.idLdap)"
        />
        <div v-show="!isBusy && filteredUsers.length === 0 && !editMode">
          {{ $t("community.no_users") }}
        </div>
      </div>
      <as-modal
        :isVisible="showAddUpdateSafetyModal"
        class="reduced-form-modal"
      >
        <user-form
          :user="userToUpdate"
          :entreprises="entreprisesOptions"
          :isClosed="!showAddUpdateSafetyModal"
          @onClose="hideUserModal"
          @onSubmit="saveUser"
        />
      </as-modal>
    </as-container>
  </div>
</template>

<script>
// @ is an alias to /src
import AsTitle from "@/components/Elements/AsTitle.vue";
import AsContainer from "@/components/Containers/AsContainer.vue";
import AsModal from "@/components/Modals/AsModal.vue";

import SafetyCardBlock from "@/components/Community/SafetyCardBlock.vue";
import AddSafetyBlock from "@/components/Community/AddSafetyBlock.vue";
import UserForm from "@/components/Forms/UserForm.vue";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";

import usersServiceApi from "../services/usersServiceApi";
import entreprisesServiceApi from "../services/entreprisesServiceApi";
import localeChangeDetectionMixins from "../mixins/localeChangeDetectionMixins";
import roleMixins from "../mixins/roleMixins";
import apiEventTypes from "@/constantes/apiEventTypes";
import { SAFETY_TEAM, ADMIN_TEAM } from "../constantes/roles";

export default {
  name: "Community",
  components: {
    AsTitle,
    AsContainer,
    AsModal,
    SafetyCardBlock,
    AddSafetyBlock,
    Dropdown,
    Button,
    ProgressSpinner,
    UserForm,
  },
  computed: {
    entreprisesOptions() {
      return this.entreprises.map((el) => {
        return {
          label: `${el.nom}`,
          value: el.id,
        };
      });
    },
  },
  watch: {
    editMode() {
      this.setFilteredUsers();
    },
  },
  data() {
    return {
      users: [],
      filteredUsers: [],
      entreprises: [],
      isBusy: false,
      selectedEntreprise: null,
      showAddUpdateSafetyModal: false,
      userToUpdate: undefined,
      editMode: false,
    };
  },
  mixins: [localeChangeDetectionMixins, roleMixins],
  beforeMount() {
    let promises = [];
    this.isBusy = true;
    promises.push(usersServiceApi.getUsers());
    promises.push(entreprisesServiceApi.getEntreprises());

    Promise.all(promises).then((results) => {
      this.users = results[0];
      this.setFilteredUsers();
      this.entreprises = results[1];
      this.isBusy = false;
    });
  },
  methods: {
    onLocaleChange() {
      this.getEntreprises().then((result) => {
        this.entreprises = result;
      });
    },
    onSelectCompany() {
      this.isBusy = true;
      this.getUsers().then((results) => {
        this.users = results;
        this.setFilteredUsers();
        this.isBusy = false;
      });
    },
    getEntreprises() {
      return entreprisesServiceApi
        .getEntreprises()
        .then((result) => {
          return Promise.resolve(result);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    getEntreprise(id) {
      return this.entreprises.find((el) => el.id === id);
    },
    getUsers() {
      return usersServiceApi
        .getUsers(
          this.selectedEntreprise ? this.selectedEntreprise.value : null
        )
        .then((result) => {
          return Promise.resolve(result);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    deleteUser(idLdap) {
      usersServiceApi.deleteUser(idLdap).then(() => {
        this.getUsers().then((result) => {
          this.users = result;
          this.setFilteredUsers();
        });
      });
    },
    showUserModal() {
      this.userToUpdate = undefined;
      this.showAddUpdateSafetyModal = true;
    },
    showUpdateUserModal(idLdap) {
      this.userToUpdate = this.filteredUsers.find((el) => el.idLdap === idLdap);
      this.showAddUpdateSafetyModal = true;
    },
    hideUserModal() {
      this.showAddUpdateSafetyModal = false;
      this.userToUpdate = undefined;
    },
    async saveUser(event) {
      if (event.data.file) {
        let reader = new FileReader();
        let promise = new Promise((resolve, reject) => {
          reader.readAsDataURL(event.data.file);
          reader.onloadend = function () {
            let base64data = reader.result;
            event.data.photo = base64data;
            delete event.data.file;
            return resolve();
          };
          reader.onerror = () => {
            reject();
          };
        });
        await promise;
      }
      if (event.updated) {
        usersServiceApi.updateUser(event.data).then(() => {
          this.getUsers().then((result) => {
            this.users = result;
            this.setFilteredUsers();
          });
          this.showAddUpdateSafetyModal = false;
          this.userToUpdate = undefined;
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_title"),
                message: this.$t("popup_messages.success_message"),
              },
            })
          );
        });
      } else {
        usersServiceApi.saveNewUser(event.data).then(() => {
          this.getUsers().then((result) => {
            this.users = result;
            this.setFilteredUsers();
          });
          this.showAddUpdateSafetyModal = false;
          this.userToUpdate = undefined;
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_title"),
                message: this.$t("popup_messages.success_message"),
              },
            })
          );
        });
      }
    },

    setFilteredUsers() {
      this.filteredUsers = this.editMode
        ? this.users
        : this.users.filter((e) => ![SAFETY_TEAM, ADMIN_TEAM].includes(e.type));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors.scss";
#community {
  .p-progress-spinner {
    height: 150px !important;
  }
}
.filters {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px;
  border-radius: 20px;
  background-color: $light_blue;
  box-shadow: 0px 0px 50px -15px $shadow_color;
  .p-dropdown {
    width: 100%;
    border-radius: 20px;
  }
}
.safety-card-container {
  margin: 0 -20px;
}
.admin {
  margin: 20px;
  padding: 0;

  .p-button {
    margin: 0;
    border-radius: 50px;
    box-shadow: 0px 0px 50px -15px $shadow_color;
  }
}
</style>
